import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47d12217"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mcp-top-tip" }
const _hoisted_2 = { class: "mcp-top-tip-content" }
const _hoisted_3 = { class: "mcp-bottom-operations" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_SlideI18nText = _resolveComponent("SlideI18nText")!
  const _component_MetaButton = _resolveComponent("MetaButton")!
  const _component_MetaPopper = _resolveComponent("MetaPopper")!

  return (_openBlock(), _createBlock(_component_MetaPopper, {
    visible: _ctx.interVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.interVisible) = $event)),
    popperOptions: _ctx.popperOptions,
    zIndex: _ctx.zIndex,
    customPopperClass: _ctx.customPopperClass + ' confirm-popper',
    customWrapperClass: _ctx.customWrapperClass,
    popperContainer: _ctx.popperContainer,
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SvgIcon, {
          class: _normalizeClass(["mcp-top-tip-icon", {[_ctx.kind]:_ctx.kind}]),
          name: "info"
        }, null, 8, ["class"]),
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MetaButton, {
          class: "mcp-bo-btn",
          onClick: _ctx.handleCancelBtnClicked
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SlideI18nText, { path: "cancel" })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_MetaButton, {
          class: "mcp-bo-btn",
          onClick: _ctx.handleConfirmBtnClicked
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SlideI18nText, { path: "confirm" })
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 3
  }, 8, ["visible", "popperOptions", "zIndex", "customPopperClass", "customWrapperClass", "popperContainer", "disabled"]))
}