
import {Options, Vue} from 'vue-class-component';
import store from "@/store";
import MetaImage from '@/components/MetaImage.vue'
import {DomEventListenerManager, copyToClipboard, colorLog} from "@/utils";
import MetaPopper from "@/components/MetaPopper.vue";
import {ModifierArguments, State} from "@popperjs/core";
import LoadingPanel from "@/components/LoadingPanel.vue";
import {MetaLocales} from '@/types/Lang';
import MetaConfirmPopperV2 from "@/components/MetaConfirmPopperV2.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import RotateRing from "@/components/RotateRing.vue";
import CreationImage from "@/components/CreationImage.vue";
import CreationData from "@/types/api/CreationData";
import {generateHighResolutionImage, getCreationShareLink, updateCreationPublished} from '@/api';
import {ElMessage} from "element-plus";
import {errorLog} from "@/config";
import {ViewNames} from "@/router";
@Options<Detail>({
  components: {
    MetaImage,
    MetaPopper,
    LoadingPanel,
    MetaConfirmPopperV2,
    FadeTransition,
    RotateRing,
    CreationImage
  },
  props: {
  },
  watch:{
    '$route.params.index':{
      handler(this:Detail,newVal:string){
        if(this.$route.name === ViewNames.Detail){
          this.updateCurrentDetailImage(store.prodImages.creations[Number(newVal)]);
        }
      },
      immediate:true
    },
  },
  computed:{
    currentImageIsHD(this:Detail){
      return this.currentDetailImage?.url1024 || this.currentDetailImage?.url2048;
    },
    previewSrcList(this:Detail){
      return store.prodImages.creations.map((creation) => creation.url2048 || creation.url1024 || creation.urlSmall );
    }
  }
})
export default class Detail extends Vue {
  loadingProgress = 10;
  loadingPanelVisible = false;
  rightPopperVisible = false;
  highResolutionPopperVisible = false;
  loadingHD = false;
  MetaLocales = MetaLocales;
  previewSrcList!:string[];
  rightPopperOptions = {
    placement:'bottom-end',
    onFirstUpdate(state:State){
      const {popper,reference} = state.elements;
    }
  }
  shareLink = 'drawdraw.com/s/exasdasdxzcxzcsxqweqnm87';
  currentDetailImage:CreationData | null = null;
  currentImageIsHD!:boolean;
  domEventListenerManager = new DomEventListenerManager();
  $refs!:{
    hdPopper:MetaConfirmPopperV2,
    rightMetaPopper:MetaPopper,
    creationImage:CreationImage,
  }
  created(){
    this.domEventListenerManager.registerListener(window,'beforeunload',(event:Event) => {
      event.preventDefault();
      event.returnValue = false;
    });
  }

  mounted(){
    this.domEventListenerManager.registerListener(window,'keydown',(e) => {
      const event:KeyboardEvent = e;
      if(['ArrowLeft','ArrowRight'].includes(event.key)){
        let index:string;
        // this.rightPopperVisible = false;
        if(event.key === 'ArrowLeft'){
          index = String( Number(this.$route.params.index) - 1  > -1 ? Number(this.$route.params.index) - 1 : store.prodImages.creations.length - 1 );
        }else{
          index = String((Number(this.$route.params.index) + 1) % store.prodImages.creations.length);
        }

        this.$router.push({
          params:{
            index
          }
        });
      }

    });
  }

  handleSwitchBtnClicked(isLeft = false){
    let index = '';
    if(isLeft){
      index = String( Number(this.$route.params.index) - 1  > -1 ? Number(this.$route.params.index) - 1 : store.prodImages.creations.length - 1 );
    }else{
      index = String((Number(this.$route.params.index) + 1) % store.prodImages.creations.length);
    }

    this.$router.push({
      params:{
        index
      }
    });
  }

  handleSwitchPublishClicked(){
    this.updateCreationPublished();
  }

  handleDownloadBtnClicked(){
    if(!this.currentDetailImage) return;
    let url = '';
    const imageURI =  this.currentDetailImage.url2048 || this.currentDetailImage.url1024 || this.currentDetailImage.urlSmall;
    if(imageURI.indexOf('data:')){
      // let downloadUrl = URL.createObjectURL(myBlob);
      const blob = this.base64toBlob(imageURI);
      url = URL.createObjectURL(blob);
    }else{
      url = imageURI;
    }

    const a = document.createElement('a');
    a.setAttribute('href',url);
    a.setAttribute("download", this.currentDetailImage.prompt + this.$route.params.index);
    a.setAttribute("target", "_blank");
    a.setAttribute('accept',".jpg");
    let clickEvent = document.createEvent("MouseEvents");
    clickEvent.initEvent("click", true, true);
    a.dispatchEvent(clickEvent);
  }

  base64toBlob(base64String:string) {
    let arr = base64String.split(","),
        mime = arr[0].match(/:(.*?);/)![1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  async updateCurrentDetailImage(detail:CreationData){
    this.currentDetailImage = detail;
    const res = await getCreationShareLink(detail.id);
    this.shareLink = res.data.data;
  }

  async getHighResolution(){
    this.loadingHD = true;
    this.loadingPanelVisible = true;
    this.highResolutionPopperVisible = false;
    try{
      const [creationData] = await generateHighResolutionImage(this.currentDetailImage!.id,(p)=>{
        this.loadingProgress = p.ratio * 100;
      });
      colorLog.blue('generateHighResolutionImage',creationData);
      this.currentDetailImage!.url1024 = creationData.urlSmall;
      store.updateProdImages(Number(this.$route.params.index),this.currentDetailImage!);
    }catch (e){
      if(typeof e === 'string'){
        ElMessage.warning(this.$t(e));
      }else{
        ElMessage.error(e);
      }
    }
    this.loadingHD = false;
    this.loadingPanelVisible = false;
  }

  handleHDBtnClicked(){
    if(this.loadingHD || this.currentImageIsHD) return;
    this.highResolutionPopperVisible = !this.highResolutionPopperVisible;
  }

  handleShareBtnClicked(){
    (this.$refs!.rightMetaPopper as MetaPopper).popperInstance!.update();
    this.rightPopperVisible = !this.rightPopperVisible;
  }

  handleBackBtnClicked(){
    if(store.backViewName){
      this.$router.push({
        name:store.backViewName
      });
    }else{
      errorLog.dev('store.backViewName为空,可能是bug.');
      this.$router.back();
    }
  }

  beforeUnmount(){
    this.domEventListenerManager.destroy();
  }

  updateCreationPublished(){
    updateCreationPublished(this.currentDetailImage?.id,this.currentDetailImage?.published).then(res=>{
      store.updateProdImages(Number(this.$route.params.index),this.currentDetailImage!);
      //show success
    })
  }

  handleCopyLinkBtnClicked(){
    copyToClipboard(this.shareLink);
    ElMessage.info(this.$t('copyTip'));
  }
}
