
import {Options, Vue} from 'vue-class-component';
import MetaPopper from "@/components/MetaPopper.vue";
import {Instance, OptionsGeneric} from "@popperjs/core";
import {colorLog} from "@/utils";

@Options<MetaConfirmPopperV2>({
  components: {
    MetaPopper,

  },
  props: {
    kind:{
      required:false,
      type: String,
      default: 'warn'
    },
    disabled:{
      required:false,
      default:false
    },
    popperContainer:{
      type:String,
      required:false,
      default:'#app'
    },
    customWrapperClass:{
      type:String,
      required:false,
      default:''
    },
    customPopperClass:{
      type:String,
      required:false,
      default:''
    },
    visible:{
      type:Boolean,
      required:false,
      default:false,
    },
    zIndex:{
      required:false,
      type:String,
      default:'auto'
    },
    popperOptions:{
      required:false
    },
    virtualElement:{
      required:false,
      default:null
    }
  },
  watch:{
    visible:{
      handler:function(newVis:boolean){
        this.interVisible = newVis;
      },
      immediate:true
    },
    interVisible:{
      handler(newVis:boolean){
        this.$emit('update:visible',newVis);
      }
    }
  },
  emits:['cancel','confirm','update:visible']
})
export default class MetaConfirmPopperV2 extends Vue {
  zIndex!:string;
  disabled = false;
  popperContainer!:string;
  popperInstance!:Instance;
  popperOptions!:OptionsGeneric<any>;
  customPopperClass!:string;
  customWrapperClass!:string;
  visible!:boolean;
  interVisible = false;
  kind!: 'warn' | 'info' | 'error';

  handleCancelBtnClicked(){
    this.$emit('cancel');
  }

  handleConfirmBtnClicked(){
    this.$emit('confirm');
  }

}
